export const formatToRupiah = (amount: number) => {
  return new Intl.NumberFormat("id-ID", {
    style: "currency",
    currency: "IDR",
    minimumFractionDigits: 0,
  }).format(amount);
};

export const formatTime = (minutes: number) => {
  const hours = Math.floor(minutes / 60);
  const remainingMinutes = minutes % 60;
  return `${hours} hours ${remainingMinutes} minutes`;
};

export const formatDate = (date: Date) => {
  return date.toLocaleDateString("id-ID", {
    year: "numeric",
    month: "long",
    day: "numeric",
  });
};

export const isISODateString = (value: string) => {
  const isoDateRegex = /^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}.\d{3}Z$/;
  return isoDateRegex.test(value);
};

export const generateRandomId = (length: number = 10): string => {
  const characters = "ABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789";
  const numbers = "0123456789";
  const letters = "ABCDEFGHIJKLMNOPQRSTUVWXYZ";

  let randomId = "";

  // Pastikan ada minimal satu angka dan satu huruf
  randomId += numbers.charAt(Math.floor(Math.random() * numbers.length));
  randomId += letters.charAt(Math.floor(Math.random() * letters.length));

  // Isi sisa panjang ID dengan karakter acak
  for (let i = 0; i < length - 2; i++) {
    randomId += characters.charAt(
      Math.floor(Math.random() * characters.length)
    );
  }

  // Acak urutan karakter
  randomId = randomId
    .split("")
    .sort(() => Math.random() - 0.5)
    .join("");

  // Tambahkan timestamp
  const timestamp = Date.now().toString(36); // Mengubah timestamp ke basis 36

  return `GP-${randomId}-${timestamp}`;
};
