// components/SpecialOfferSection.tsx
import React from "react";
import ButtonText from "../../buttons/ButtonText";
import { useRouter } from "next/navigation";

const SpecialOfferSection: React.FC = () => {
  const router = useRouter();

  const handleClick = () => {
    router.push(
      "https://www.instagram.com/garisprtama?utm_source=ig_web_button_share_sheet&igsh=ZDNlZDc0MzIxNw=="
    ); // Ganti dengan route tujuan Anda
  };
  return (
    <div className="bg-font p-8 md:p-8 rounded-2xl shadow-md  mx-6 lg:mx-24  my-20   py-10  justify-center flex flex-col">
      <h1 className="text-3xl text-white text-center mb-6 font-bold tracking-wide">
        Dapatkan Info Penawaran Spesial dari Garis Prtama
      </h1>
      <ButtonText
        text="Follow On Instagram"
        icon={null}
        borderRadius="rounded-2xl"
        margin="mx-8"
        textSize="text-lg"
        onClick={handleClick}
      />
    </div>
  );
};

export default SpecialOfferSection;
