import React, { createContext, useContext, useState } from "react";
import { fetchName } from "../services/userServices";

interface UserContextType {
  userName: string | null;
  isLoading: boolean;
  fetchUserName: () => void;
}

const UserContext = createContext<UserContextType>({} as UserContextType);

export const useUser = () => {
  return useContext(UserContext);
};

export const UserProvider: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const [userName, setUserName] = useState<string | null>(null);
  const [isLoading, setIsLoading] = useState(true);

  const fetchUserName = async () => {
    try {
      const response = await fetchName();
      setUserName(response.user?.name || null);
    } catch (error) {
      console.error("Error fetching user name:", error);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <UserContext.Provider value={{ userName, isLoading, fetchUserName }}>
      {children}
    </UserContext.Provider>
  );
};
