"use client";
import React, { useState } from "react";
import Slider from "react-slick";
import TestimonialCard from "../cards/TestimonialStudent";

interface TestimonialCardProps {
  avatar: string;
  name: string;
  comment: string;
  location: string;
}

interface TestimonialSliderProps {
  settings?: any;
  testimonialData: TestimonialCardProps[];
}

const TestimonialSlider: React.FC<TestimonialSliderProps> = ({
  settings,
  testimonialData,
}) => {
  const [currentSlide, setCurrentSlide] = useState(0);

  const defaultSettings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 1,
    beforeChange: (oldIndex: number, newIndex: number) => {
      setCurrentSlide(newIndex);
    },
    appendDots: (dots: React.ReactNode) => (
      <div>
        <ul className="flex justify-center mt-8 space-x-2">{dots}</ul>
      </div>
    ),
    customPaging: (i: number) => (
      <div
        className={`w-3 h-3  rounded-full cursor-pointer ${
          i === currentSlide ? "bg-primary" : "bg-font"
        }`}
      ></div>
    ),
    responsive: [
      {
        breakpoint: 1600,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 1280,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          initialSlide: 2,
        },
      },
    ],
  };

  const appliedSettings = { ...defaultSettings, ...settings };

  return (
    <Slider {...appliedSettings}>
      {testimonialData.map((item, index) => (
        <TestimonialCard key={index} {...item} />
      ))}
    </Slider>
  );
};

export default TestimonialSlider;
