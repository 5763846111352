export const testimonials = [
  {
    avatar: "/images/avatar/1.svg",
    name: "John Doe",
    comment: "Garis Pratama is an amazing platform for learning!",
    rating: 5,
  },
  {
    avatar: "/images/avatar/2.svg",
    name: "Jane Smith",
    comment:
      "The instructors are knowledgeable and supportive.The instructors are knowledgeable and supportive.The instructors are knowledgeable and supportive.",
    rating: 5,
  },

  {
    avatar: "/images/avatar/3.svg",
    name: "Jane Smith",
    comment:
      "The instructors are knowledgeable and supportive.The instructors are knowledgeable and supportive.The instructors are knowledgeable and supportive.",
    rating: 5,
  },
];

export const testimonialsV2 = [
  {
    avatar: "/images/avatar/1.svg",
    name: "gopal alexander",
    comment:
      "Materinya jelas dan praktis, serta para pengajarnya sangat berpengalaman. Saya merasa lebih siap dan percaya diri untuk menghadapi tantangan di dunia kerja setelah mengikuti kursus di sini.",
    location: "Surabaya",
  },
  {
    avatar: "/images/avatar/2.svg",
    name: "boboiboy",
    comment:
      "Materinya jelas dan praktis, serta para pengajarnya sangat berpengalaman. Saya merasa lebih siap dan percaya diri untuk menghadapi tantangan di dunia kerja setelah mengikuti kursus di sini.",
    location: "Jakarta",
  },

  {
    avatar: "/images/avatar/3.svg",
    name: "Anita Hikmah",
    comment:
      "Materinya jelas dan praktis, serta para pengajarnya sangat berpengalaman. Saya merasa lebih siap dan percaya diri untuk menghadapi tantangan di dunia kerja setelah mengikuti kursus di sini.",
    location: "Bandung",
  },
];
