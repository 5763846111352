import { FAQItemProps } from "../types/faqItems";

export const faqData: FAQItemProps[] = [
  {
    question: "Apakah Harus Berlangganan?",
    answer:
      "Nggak, Kamu cuma bayar sekali dan bisa di akses semagernya, plus ada materi update-nya juga dong",
  },
  {
    question: "Bagaimana Mekanisme Kelasnya?",
    answer: "",
  },
  {
    question: "Apakah Dapat Sertifikat?",
    answer: "",
  },
  {
    question: "Apakah Ada Grup Diskusinya?",
    answer: "",
  },
  {
    question: "Apakah Cocok Untuk Pemula?",
    answer: "",
  },
  {
    question: "Berapa Lama Kelasnya?",
    answer: "",
  },
  {
    question: "Apakah Perlu Perangkat Khusus?",
    answer: "",
  },
];
