"use client";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import Slider from "react-slick";
import GeneralistCard from "../cards/Generalist";
import LoadingGeneralistCard from "../loading/couses";
import { useCart } from "@/app/contex/cart";
import { Course } from "@/app/types/courseCard";
import debounce from "lodash.debounce";

interface GeneralistSliderProps {
  settings?: any;
  courseData: Course[];
}

const GeneralistSlider: React.FC<GeneralistSliderProps> = ({
  settings,
  courseData,
}) => {
  const [currentSlide, setCurrentSlide] = useState(0);
  const { addItemToCart, isInCart, cart } = useCart();
  const [loadingCardsCount, setLoadingCardsCount] = useState(4); // Default to 4

  // Function to determine loading cards based on screen size
  const updateLoadingCardsCount = useCallback(() => {
    const mobileQuery = window.matchMedia("(max-width: 768px)");
    const tabletQuery = window.matchMedia("(max-width: 1280px)");

    if (mobileQuery.matches) {
      setLoadingCardsCount(1); // Mobile (sm)
    } else if (tabletQuery.matches) {
      setLoadingCardsCount(3); // Medium (md)
    } else {
      setLoadingCardsCount(4); // Large (lg)
    }
  }, []);

  // Set loading cards count on initial load and window resize
  useEffect(() => {
    updateLoadingCardsCount();
    const debouncedResizeHandler = debounce(updateLoadingCardsCount, 300);
    window.addEventListener("resize", debouncedResizeHandler);
    return () => {
      window.removeEventListener("resize", debouncedResizeHandler);
    };
  }, [updateLoadingCardsCount]);
  const defaultSettings = useMemo(
    () => ({
      dots: true,
      infinite: courseData.length > 1,
      speed: 500,
      autoplay: true,
      autoplaySpeed: 3000,
      slidesToShow: Math.min(courseData.length, 4) || 1,
      slidesToScroll: 1,
      beforeChange: (oldIndex: number, newIndex: number) => {
        setCurrentSlide(newIndex);
      },
      appendDots: (dots: React.ReactNode) => (
        <div>
          <ul className="flex justify-center mt-8 space-x-2">{dots}</ul>
        </div>
      ),
      customPaging: (i: number) => (
        <div className=" flex justify-center items-center">
          <div
            className={`w-3 h-3 mt-2 rounded-full cursor-pointer ${
              i === currentSlide ? "bg-primary" : "bg-font"
            }`}
          />
        </div>
      ),
      responsive: [
        {
          breakpoint: 1600,
          settings: {
            slidesToShow: 3,
            slidesToScroll: 1,
            infinite: true,
            dots: true,
          },
        },
        {
          breakpoint: 1280,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 1,
            infinite: true,
            dots: true,
          },
        },
        {
          breakpoint: 768,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
            infinite: false,
          },
        },
      ],
    }),
    [courseData.length, currentSlide]
  );

  const appliedSettings = { ...defaultSettings, ...settings };

  const debouncedAddToCart = useCallback(
    debounce((id: string, quantity: number, price: number) => {
      addItemToCart(id, quantity, price);
    }, 1000),
    [addItemToCart]
  );
  return (
    <Slider {...appliedSettings} className="">
      {courseData.length === 0
        ? // Render loading cards jika courseData kosong
          Array.from({ length: loadingCardsCount }).map((_, index) => (
            <LoadingGeneralistCard key={index} />
          ))
        : // Render courseData jika tidak kosong
          courseData.map((data) => (
            <GeneralistCard
              key={data.id}
              thumbnail={data.thumbnail}
              title={data.title}
              studentCount={data.studentCount || 0}
              totalMinutes={data.totalMinutes}
              mentor={data.mentor.name}
              mentorAvatar={data.mentor.avatar}
              price={data.price}
              discountPrice={data.discountPrice}
              onAddToCart={() =>
                debouncedAddToCart(data.id, 1, data.discountPrice)
              }
              isInCart={isInCart(data.id)}
              courseId={data.id}
            />
          ))}
    </Slider>
  );
};

export default GeneralistSlider;
