import { useEffect, useState } from "react";

//CEKAT AI
const ChatWidget = () => {
  const [isDesktop, setIsDesktop] = useState(false);

  useEffect(() => {
    // Function to check screen size
    const checkScreenSize = () => {
      const width = window.innerWidth;
      // Set to true if the screen width is greater than 768px (tablet/desktop)
      setIsDesktop(width >= 768);
    };

    // Check the screen size on initial load
    checkScreenSize();

    // Add resize event listener to update state when window is resized
    window.addEventListener("resize", checkScreenSize);

    return () => {
      // Cleanup the event listener on component unmount
      window.removeEventListener("resize", checkScreenSize);
    };
  }, []);

  useEffect(() => {
    if (typeof window !== "undefined" && isDesktop) {
      window.mychat = window.mychat || {};
      window.mychat.server = process.env.NEXT_PUBLIC_CEKAT_AI_URL as string; // Use NEXT_PUBLIC_ prefix
      window.mychat.iframeWidth = "100px";
      window.mychat.iframeHeight = "200px";
      window.mychat.accessKey = process.env.NEXT_PUBLIC_CEKAT_AI_KEY as string; // Use NEXT_PUBLIC_ prefix

      // Create the script element
      const mychatScript = document.createElement("script");
      mychatScript.type = "text/javascript";
      mychatScript.async = true;
      mychatScript.src = window.mychat.server;

      // Insert the script into the document
      const firstScript = document.getElementsByTagName("script")[0];
      if (firstScript && firstScript.parentNode) {
        firstScript.parentNode.insertBefore(mychatScript, firstScript);
      }

      // Use MutationObserver to observe changes in the DOM if needed
      const observer = new MutationObserver((mutationsList, observer) => {
        for (let mutation of mutationsList) {
          if (mutation.type === "childList") {
            console.log("A child node has been added or removed.");
            // Handle any necessary logic here when nodes are inserted or removed
          }
        }
      });

      // Observe changes in the document body or a specific node
      observer.observe(document.body, {
        childList: true, // Listen for the addition or removal of child nodes
        subtree: true, // Listen through the entire subtree of nodes
      });

      // Clean up MutationObserver when component unmounts
      return () => {
        observer.disconnect();
      };
    }
  }, [isDesktop]); // Only run effect when 'isDesktop' changes

  return null;
};

export default ChatWidget;
