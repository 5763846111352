"use client";
import { useState } from "react";
import Link from "next/link";
import { FaBars } from "react-icons/fa";
import { IoClose } from "react-icons/io5";
import { useSession } from "next-auth/react";
import Image from "next/image";
import useClickOutside from "@/hooks/useClickOutside";

const menuItems = [
  { href: "/", label: "Home" },
  // { href: "/about-us", label: "About Us" },
  { href: "/courses", label: "Courses" },
  { href: "/cart", label: "Cart" },
];
const NavbarMenu: React.FC = () => {
  const { data: session, status } = useSession();

  const [menuOpen, setMenuOpen] = useState(false);

  const toggleMenu = () => setMenuOpen((prev) => !prev);
  const closeMenu = () => setMenuOpen(false);
  const menuRef = useClickOutside(closeMenu);

  const renderMenuItems = () =>
    menuItems.map((item) => (
      <Link
        key={item.href}
        href={item.href}
        className={`block hover:text-primaryYellow ${
          item.href === "/" ? "border-t-2 border-primary pt-4" : ""
        }`}
        onClick={closeMenu}
      >
        {item.label}
      </Link>
    ));
  return (
    <div className="fixed top-0 left-0 right-0  z-50">
      {/* Fixed Header Inside Wrapper */}
      <div className="bg-font w-full h-20  flex justify-between px-[21px] py-[14px] rounded-b-xl">
        <Link
          href="/"
          className="flex items-center space-x-3"
          onClick={closeMenu}
        >
          <Image
            src="/images/logo.webp"
            alt="Logo"
            width={400}
            height={200}
            className=" max-w-[150px] md:max-w-[250px] lg:max-w-[250px]"
            priority
          />
        </Link>

        <div
          className="bg-primaryYellow px-4 py-2 rounded-lg cursor-pointer"
          onClick={toggleMenu}
        >
          {menuOpen ? (
            <IoClose className="text-3xl" />
          ) : (
            <FaBars className="text-3xl" />
          )}
        </div>
      </div>
      {/* Dropdown Menu */}
      {menuOpen && (
        <div
          ref={menuRef}
          className="absolute top-16 left-0 bg-font w-full font-poppins font-semibold text-center  text-2xl py-4 text-white space-y-2 rounded-b-2xl"
        >
          {renderMenuItems()}
          {status === "authenticated" ? (
            <Link
              href="/dashboard"
              className="block hover:text-primaryYellow"
              onClick={closeMenu}
            >
              Dashboard
            </Link>
          ) : (
            <Link
              href="/login"
              className="block hover:text-primaryYellow"
              onClick={closeMenu}
            >
              Sign In/Sign Up
            </Link>
          )}
        </div>
      )}
    </div>
  );
};

export default NavbarMenu;
