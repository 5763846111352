import React, { createContext, useState, useContext, ReactNode } from "react";
import {
  addToCart,
  couponCart,
  deleteCart,
  deleteCartItem,
  fetchCart,
  fetchCartItems,
} from "../services/cartServices";
import { removeCartIdCookie, setCartIdCookie } from "../services/cookieService";
import { Cart, Item } from "../types/cartCard";

interface CartContextType {
  cartId: string | null;
  setCartId: (id: string) => void;
  clearCart: () => void;
  cart: Cart;
  setCart: React.Dispatch<React.SetStateAction<Cart>>;
  loading: boolean;
  error: string | null;
  initializeCart: () => void;
  fetchCart: () => void;
  fetchCartItem: () => void;
  addItemToCart: (
    courseId: string,
    quantity: number,
    price: number
  ) => Promise<{ message: string; cart: Cart } | undefined>;
  addCouponToCart: (
    code: string
  ) => Promise<{ message: string; cart: Cart } | undefined>;
  removeItemFromCart: (
    courseId: string
  ) => Promise<{ message: string } | undefined>;
  removeCart: (cartId: string) => Promise<{ message: string } | undefined>;
  isInCart: (itemId: string) => boolean;
}

const CartContext = createContext<CartContextType>({} as CartContextType);

export const useCart = () => {
  return useContext(CartContext);
};

export const CartProvider: React.FC<{ children: ReactNode }> = ({
  children,
}) => {
  const [cart, setCart] = useState<any>(null);
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<string | null>(null);
  const [cartId, setCartId] = useState<string | null>(null);

  const clearCart = () => {
    setCartId(null);
    removeCartIdCookie();
  };

  const isInCart = (itemId: string) => {
    return cart?.items?.some((item: Item) => item.course.id === itemId);
  };

  const fetchCartItem = async () => {
    try {
      const response = await fetchCartItems();
      setCart(response.data.cart);
    } catch (err) {
      setError("cart item not found");
    } finally {
      setLoading(false);
    }
  };

  const addCouponToCart = async (
    code: string
  ): Promise<{ message: string; cart: Cart } | undefined> => {
    try {
      const response = await couponCart(cart.id, code);
      setCart(response.data.cart);
      return {
        message: response.data.message,
        cart: response.data.cart,
      };
    } catch (err: any) {
      console.error(err.message);
      setError("Invalid coupon code");
    }
  };

  const addItemToCart = async (
    courseId: string,
    quantity: number,
    price: number
  ): Promise<{ message: string; cart: Cart } | undefined> => {
    try {
      const response = await addToCart(cartId, {
        courseId,
        quantity,
        price,
      });
      //jika cartId kosong
      if (!cartId) {
        setCartId(response.data.cart.id);
        setCartIdCookie(response.data.cart.id);
      }
      setCart(response.data.cart);
      return {
        message: response.data.message,
        cart: response.data.cart,
      };
    } catch (err: any) {
      console.error(err.message);
      setError("Failed to add item to cart");
    }
  };

  const removeItemFromCart = async (
    itemId: string
  ): Promise<{ message: string } | undefined> => {
    try {
      setCart((prevCart: Cart) => {
        return {
          ...prevCart,
          items: prevCart.items.filter((item: Item) => item.id !== itemId),
          total:
            prevCart.total -
            (prevCart.items.find((item) => item.id === itemId)?.price || 0),
        };
      });
      const response = await deleteCartItem(cart.id, itemId);
      return {
        message: response.data.message,
      };
    } catch (err: any) {
      console.error(err.message);
      setError("Failed to remove item from cart");
    }
  };

  const removeCart = async (
    itemId: string
  ): Promise<{ message: string } | undefined> => {
    try {
      const response = await deleteCart(cart.id);
      setCart(null);
      clearCart();
      return {
        message: response.data.message,
      };
    } catch (err: any) {
      console.error(err.message);
      setError("Failed to remove item from cart");
    }
  };

  const initializeCart = async () => {
    try {
      const response = await fetchCart();
      if (response.data.carts.id) {
        setCartId(response.data.carts.id);
        setCartIdCookie(response.data.carts.id);
      }
    } catch (error: any) {
      removeCartIdCookie();
      console.error("Error fetching cart:", error.message);
    }
  };

  return (
    <CartContext.Provider
      value={{
        cartId,
        setCartId,
        clearCart,
        cart,
        setCart,
        loading,
        error,
        initializeCart,
        fetchCart,
        fetchCartItem,
        addItemToCart,
        addCouponToCart,
        removeItemFromCart,
        removeCart,
        isInCart,
      }}
    >
      {children}
    </CartContext.Provider>
  );
};
