// components/FooterSection.tsx
import React, { useCallback } from "react";
import ButtonText from "../../buttons/ButtonText";
import { useRouter } from "next/navigation";
import Image from "next/image";

const FooterSection: React.FC = () => {
  const router = useRouter();

  const handleClickTemukanDisini = useCallback(() => {
    router.push("#"); // Ganti dengan route tujuan Anda
  }, [router]);

  const handleClickAdaPertanyaan = useCallback(() => {
    router.push(
      "https://wa.me/089529307135?text=I'm%20interested%20to%20Join%20class"
    ); // Ganti wa
  }, [router]);

  return (
    <div className="relative h-80  bg-black bg-opacity-50  mx-6 lg:mx-24 rounded-2xl overflow-hidden">
      <Image
        src="/images/background/landing2.webp"
        alt="image-background-footer"
        fill
        style={{ objectFit: "cover", objectPosition: "center" }}
        priority
      />
      <div className="absolute inset-0 flex flex-col items-center justify-center py-6">
        <h3 className="text-white text-2xl sm:text-3xl md:text-4xl mx-8 text-center font-medium">
          Jadilah Yang Terbaik Di setiap Bidangnya
        </h3>
        <ButtonText
          text="Temukan Disini"
          textSize="text-2xl"
          fontWeight="font-normal"
          borderRadius="rounded-xl"
          paddingX="px-10"
          margin="my-4"
          icon={null}
          onClick={handleClickTemukanDisini}
        />
        <ButtonText
          text="Ada pertanyaan ?"
          textSize="text-2xl"
          fontWeight="font-normal"
          borderRadius="rounded-xl"
          paddingX="px-6"
          margin="my-4"
          icon={null}
          bgColor="bg-transparent border-2 border-white"
          onClick={handleClickAdaPertanyaan}
        />
      </div>
    </div>
  );
};

export default FooterSection;
