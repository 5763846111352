import Image from "next/image";
import React from "react";
import { FaQuoteRight } from "react-icons/fa";

interface TestimonialCardProps {
  avatar: string;
  name: string;
  comment: string;
  location: string;
}

const TestimonialCard: React.FC<TestimonialCardProps> = ({
  avatar,
  name,
  comment,
  location,
}) => {
  return (
    <div className="flex flex-col items-center bg-white rounded-lg bg-opacity-50 border-2 border-gray-200  mx-0   md:mx-6 py-4 my-6">
      <div className="flex flex-col items-center mb-4 w-full  lg:mx-10">
        <div className="text-center text-lg text-gray-600 px-4">{comment}</div>
        <Image
          src={avatar}
          alt={name}
          width={64}
          height={64}
          style={{ objectFit: "cover" }}
          className="rounded-full my-6 border-2 border-gray-200"
        />

        <div className="text-lg font-semibold mt-2">{name}</div>
        <span>{location}</span>
      </div>
    </div>
  );
};

export default TestimonialCard;
