// components/GeneralistSection.tsx
import React, { useEffect, useState } from "react";
import GeneralistSlider from "../../sliders/Generalist";
import { Course } from "@/app/types/courseCard";
import { fetchCourses } from "@/app/services/courseServices";
import Link from "next/link";

const GeneralistSection: React.FC = () => {
  const [courses, setCourses] = useState<Course[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetchCourses();
        setCourses(response.data.courses);
      } catch (err) {
        console.error(err);
        setError("Failed to fetch courses");
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, []);
  return (
    <div className="bg-gray-100 p-4 md:p-8 rounded-lg shadow-md  my-20 py-10 mx-6 lg:mx-24">
      <h1 className="text-3xl font-semibold text-center mb-2">
        Kelas Generalist
      </h1>
      <p className="text-center text-gray-600 mb-4">
        Belajar Produktif Dengan Materi Yang Efektif Bersama Para Mentor
        Ekslusif
      </p>

      <GeneralistSlider courseData={courses} />

      <div className="text-center mt-10">
        <Link
          href="/courses"
          className="bg-black text-primary text-[1rem] py-2 px-6 rounded-2xl hover:bg-yellow-600 hover:text-white transition"
        >
          Tampilkan Semua Kelas
        </Link>
      </div>
    </div>
  );
};

export default GeneralistSection;
