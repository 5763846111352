// components/InvestmentSection.tsx
"use client";
import React from "react";
import ButtonText from "../../buttons/ButtonText";
import { useRouter } from "next/navigation";
const InvestmentSection: React.FC = () => {
  const router = useRouter();
  const handleNavigate = () => {
    router.push("/courses");
  };
  return (
    <div className="bg-font p-8 md:p-8 rounded-2xl shadow-md  mx-6 lg:mx-24 my-20 py-10  justify-center flex flex-col">
      <h1 className="text-3xl text-white text-center mb-6 tracking-wide">
        Investasi Sekali Dapat Update Berkali - Kali.
      </h1>
      <ButtonText
        text="Tampilkan Semua Kelas"
        icon={null}
        borderRadius="rounded-2xl"
        margin="mx-8"
        textSize="text-lg"
        onClick={handleNavigate}
      />
    </div>
  );
};

export default InvestmentSection;
