import {
  COURSES_PUBLIC_API,
  COURSE_API,
  COURSE_ENROLL_API,
  COURSE_MODULE_API,
} from "../api/apiEndpoints";
import axiosInstance from "../api/axiosInstance";

//course
export const fetchCourses = async () => {
  const response = await axiosInstance.get(`${COURSES_PUBLIC_API}`);
  return response;
};

export const fetchCoursesDetailsPrivate = async (id: string) => {
  const response = await axiosInstance.get(`${COURSE_API}/${id}`);
  return response;
};

export const fetchCoursesPrivate = async (status: string) => {
  const response = await axiosInstance.get(`${COURSE_API}?status=${status}`);
  return response;
};

//enrolled
export const fetchCountEnrolled = async () => {
  const response = await axiosInstance.get(`${COURSE_ENROLL_API}`);
  return response;
};

export const updateStatusEnrollment = async (
  courseId: string,
  newStatus: string
) => {
  const response = await axiosInstance.patch(
    `${COURSE_API}/${courseId}/enrollment`,
    {
      newStatus,
    }
  );
  return response;
};

export const createEnrollmentCourses = async (courseIds: Array<string>) => {
  const response = await axiosInstance.post(`${COURSE_ENROLL_API}`, {
    courseIds,
  });
  return response;
};

//module
export const fetchCourseModules = async (moduleId: string) => {
  const response = await axiosInstance.get(`${COURSE_MODULE_API}/${moduleId}`);
  return response;
};
