// src/app/layout.tsx
"use client";
import { ReactNode } from "react";
import { usePathname } from "next/navigation";
import "../app/styles/globals.css";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "react-quill/dist/quill.snow.css"; // import styles for Quill

import { Poppins } from "next/font/google";
import NavbarMenu from "./components/NavbarMenu";
import { categoriesV2 } from "./data/categoriesData";
import CategoryList from "./components/sections/category-class/CategoryList";
import GeneralistSection from "./components/sections/generalist";
import SpecialistSection from "./components/sections/spesialist";
import TestimonialSection from "./components/sections/Testimonial";
import InvestmentSection from "./components/sections/Investment";
import FAQSection from "./components/sections/FAQSection";
import FooterSection from "./components/sections/footer";
import SpecialOfferSection from "./components/sections/SpecialOffer";
import SessionWrapper from "./contex/SessionWrapper";
import { CartProvider } from "./contex/cart";
import ChatWidget from "./components/chatWidget";
import { CourseProvider } from "./contex/course";
import { UserProvider } from "./contex/user";

const poppins = Poppins({
  subsets: ["latin"],
  weight: ["400", "500", "600", "700"],
});

const NAVBAR_PATHS = [
  "/",
  "/about-us",
  "/courses",
  "/cart",
  "/services",
  "/login",
  "/sign-up",
  "/dashboard",
  "/dashboard/settings",
  "/dashboard/profile",
  "/dashboard/enrolled",
];

const shouldShowNavbar = (path: string): boolean => {
  return (
    NAVBAR_PATHS.includes(path) ||
    path.startsWith("/dashboard/change-password") ||
    path.startsWith("/dashboard/enrolled/course/") ||
    path.startsWith("/detail-class/")
  );
};

const shouldShowSections = (path: string): boolean => {
  return NAVBAR_PATHS.includes(path) || path.startsWith("/detail-class/");
};
export default function MainLayout({ children }: { children: ReactNode }) {
  const currentPath = usePathname();

  return (
    <SessionWrapper>
      <UserProvider>
        <CartProvider>
          <CourseProvider>
            <html lang="en">
              <body className={poppins.className}>
                {shouldShowNavbar(currentPath) && <NavbarMenu />}
                <main>{children}</main>
                {shouldShowSections(currentPath) && (
                  <>
                    <CategoryList categories={categoriesV2} />
                    <GeneralistSection />
                    <SpecialistSection />
                    <TestimonialSection />
                    <InvestmentSection />
                    <FAQSection />
                    <FooterSection />
                    <SpecialOfferSection />
                    <ChatWidget />
                  </>
                )}
              </body>
            </html>
          </CourseProvider>
        </CartProvider>
      </UserProvider>
    </SessionWrapper>
  );
}
