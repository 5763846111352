import React from "react";
import CategoryItem from "./CategoryItem";

interface Category {
  image: string;
  name: string;
  position: string;
}

interface CategoryListProps {
  categories: Category[];
}

const CategoryList: React.FC<CategoryListProps> = ({ categories }) => {
  return (
    <div className="mt-20 mx-6  lg:mx-24  ">
      <h2 className="text-center text-4xl font-semibold mb-6">
        Kategori Kelas
      </h2>
      <div className="space-y-4">
        {categories.map((category, index) => (
          <CategoryItem
            key={index}
            image={category.image}
            name={category.name}
            position={category.position}
          />
        ))}
      </div>
    </div>
  );
};

export default CategoryList;
