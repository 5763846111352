import React, {
  createContext,
  useContext,
  useState,
  useEffect,
  useCallback,
} from "react";
import { Course } from "@/app/types/courseCard";
import { fetchCoursesDetailsPrivate } from "../services/courseServices";
import { setCourseIdCookie } from "../services/cookieService";

interface CourseContextType {
  course: Course;
  loading: boolean;
  setLoading: (loading: boolean) => void;
  fetchCourse: (id: string) => void;
}

const CourseContext = createContext<CourseContextType>({} as CourseContextType);

export const useCourse = () => {
  return useContext(CourseContext);
};

export const CourseProvider: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const [course, setCourse] = useState<Course>({} as Course);
  const [loading, setLoading] = useState(true);

  const fetchCourse = async (id: string) => {
    setLoading(true);
    try {
      const response = await fetchCoursesDetailsPrivate(id);
      setCourse(response.data.course);
      setCourseIdCookie(response.data.course.id);
    } catch (error) {
      console.error("Error fetching course data:", error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <CourseContext.Provider
      value={{ course, loading, setLoading, fetchCourse }}
    >
      {children}
    </CourseContext.Provider>
  );
};
