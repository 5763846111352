import React, { useState } from "react";
import { FAQItemProps } from "../types/faqItems";
import { MdKeyboardArrowRight, MdKeyboardArrowDown } from "react-icons/md";

const FAQItem: React.FC<FAQItemProps> = ({ question, answer }) => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <div>
      <button
        className={`flex justify-between items-center w-full text-left font-medium ${
          isOpen ? "text-primary" : "text-font"
        }`}
        onClick={() => setIsOpen(!isOpen)}
      >
        {question}
        <span className="text-2xl">
          {isOpen ? <MdKeyboardArrowDown /> : <MdKeyboardArrowRight />}
        </span>
      </button>
      {isOpen && (
        <p className="text-gray-700 mt-2">
          {answer || "Informasi lebih lanjut tidak tersedia."}
        </p>
      )}
      {/* Divider x */}
      <div className="mt-4 border-t-2 border-gray-100" />
    </div>
  );
};
export default FAQItem;
