import React from "react";

const LoadingGeneralistCard: React.FC = () => {
  return (
    <div className="bg-white rounded-lg overflow-hidden shadow-lg mx-1 md:mx-2 animate-pulse">
      {/* Image skeleton */}
      {/* <div className="w-full h-64 bg-gray-300"></div> */}
      <div className="flex items-center justify-center h-48 mb-4 bg-gray-300 rounded dark:bg-gray-400">
        <svg
          className="w-10 h-10 text-gray-200 dark:text-gray-600"
          aria-hidden="true"
          xmlns="http://www.w3.org/2000/svg"
          fill="currentColor"
          viewBox="0 0 20 18"
        >
          <path d="M18 0H2a2 2 0 0 0-2 2v14a2 2 0 0 0 2 2h16a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2Zm-5.5 4a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3Zm4.376 10.481A1 1 0 0 1 16 15H4a1 1 0 0 1-.895-1.447l3.5-7A1 1 0 0 1 7.468 6a.965.965 0 0 1 .9.5l2.775 4.757 1.546-1.887a1 1 0 0 1 1.618.1l2.541 4a1 1 0 0 1 .028 1.011Z" />
        </svg>
      </div>
      {/* Information skeleton */}
      <div className="p-4 space-y-4">
        {/* Title skeleton */}
        <div className="h-6 bg-gray-300 rounded w-3/4"></div>

        {/* Description skeleton */}
        <div className="flex items-center space-x-4">
          {/* First line skeleton */}
          <div className="h-4 bg-gray-300 rounded w-1/4"></div>
          {/* Second line skeleton */}
          <div className="h-4 bg-gray-300 rounded w-1/6"></div>
        </div>

        {/* Mentor skeleton */}
        <div className="flex items-center space-x-4">
          {/* Avatar skeleton */}
          <div className="h-8 w-8 bg-gray-300 rounded-full"></div>
          {/* Name skeleton */}
          <div className="h-4 bg-gray-300 rounded w-1/2"></div>
        </div>

        {/* Footer skeleton */}
        <div className="flex items-center justify-between">
          {/* Price skeleton */}
          <div className="h-6 bg-gray-300 rounded w-1/3"></div>
          {/* Button skeleton */}
          <div className="h-10 bg-gray-300 rounded w-1/4"></div>
        </div>
      </div>
    </div>
  );
};

export default LoadingGeneralistCard;
