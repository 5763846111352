import { CART_API } from "../api/apiEndpoints";
import axiosInstance from "../api/axiosInstance";

export const fetchCartItems = async () => {
  const response = await axiosInstance.get(`${CART_API}/items`);
  return response;
};
export const fetchCart = async () => {
  const response = await axiosInstance.get(`${CART_API}`);
  return response;
};
export const addToCart = async (
  cartId: string | null,
  data: { courseId: string; quantity: number; price: number }
) => {
  const payload = cartId ? { cartId, ...data } : { ...data };

  const response = await axiosInstance.post(`${CART_API}/items`, payload);
  return response;
};

export const deleteCartItem = async (cartId: string, itemId: string) => {
  const response = await axiosInstance.delete(
    `${CART_API}/${cartId}/items/${itemId}`
  );
  return response;
};

export const deleteCart = async (cartId: string) => {
  const response = await axiosInstance.delete(`${CART_API}/${cartId}`);
  return response;
};

export const couponCart = async (cartId: string, code: string) => {
  const response = await axiosInstance.post(`${CART_API}/${cartId}/coupon`, {
    code,
  });
  return response;
};
