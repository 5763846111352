import React from "react";
import { MdArrowForward } from "react-icons/md";

interface ButtonTextProps {
  text: string;
  icon?: React.ReactNode;
  onClick?: () => void;
  // Button styles
  paddingX?: string;
  paddingY?: string;
  borderRadius?: string;
  bgColor?: string;
  hoverBgColor?: string;
  activeScale?: string;
  duration?: string;
  hoverScale?: string;
  margin?: string;
  // Text styles
  textSize?: string;
  textColor?: string;
  fontWeight?: string;
  fontFamily?: string;
}

const ButtonText: React.FC<ButtonTextProps> = ({
  text,
  icon = <MdArrowForward className="text-light w-5 h-5" />,
  onClick,
  paddingX = "px-4",
  paddingY = "py-2",
  borderRadius = "rounded-3xl",
  bgColor = "bg-primary",
  hoverBgColor = "hover:bg-accent",
  activeScale = "active:scale-[.98]",
  duration = "active:duration-75",
  hoverScale = "hover:scale-[1.01]",
  textSize = "text-xs lg:text-lg",
  textColor = "text-white",
  fontWeight = "font-extrabold",
  fontFamily = "font-poppins",
  margin = "",
}) => {
  return (
    <button
      onClick={onClick}
      className={`flex flex-row justify-center items-center ${paddingX} ${paddingY} ${borderRadius} ${bgColor} ${hoverBgColor} ${activeScale} ${duration} ${hoverScale} ${margin}`}
    >
      <span className={`${textSize} ${textColor} ${fontWeight} ${fontFamily}`}>
        {text}
      </span>
      {icon && <span className="ml-2">{icon}</span>}
    </button>
  );
};

export default ButtonText;
