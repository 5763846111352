"use client";
import React from "react";
import FAQItem from "../../FaqItem";
import { faqData } from "../../../data/faqData";

const FAQSection: React.FC = () => {
  return (
    <section className="w-full overflow-hidden">
      <div className=" mx-6 lg:mx-24 lg:mx-20 py-10 md:py-20 ">
        <h2 className="text-center text-2xl lg:text-4xl font-bold text-yellow-500 mb-14">
          Pertanyaan yang Sering Ditanyakan
        </h2>
        <div className="my-4 border-t-2 border-gray-100" />
        <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
          {faqData.map((faq, index) => (
            <FAQItem key={index} {...faq} />
          ))}
        </div>
      </div>
    </section>
  );
};

export default FAQSection;
