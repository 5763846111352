import { USER_NAME_API, USER_PROFILE_API, User_API } from "../api/apiEndpoints";
import axiosInstance from "../api/axiosInstance";

export const fetchProfile = async () => {
  const response = await axiosInstance.get(`${USER_PROFILE_API}`);
  return response.data;
};

export const fetchName = async () => {
  const response = await axiosInstance.get(`${USER_NAME_API}`);
  return response.data;
};

export const updateProfile = async (data: any) => {
  const response = await axiosInstance.put(`${USER_PROFILE_API}`, data);
  return response.data;
};

export const changePassword = async (data: {
  oldPassword: string;
  newPassword: string;
  confirmPassword: string;
}) => {
  const response = await axiosInstance.put(`${User_API}/change-password`, data);
  return response.data;
};
