"use client";
import React from "react";
import TestimonialSlider from "../../sliders/TestimonialSlider";
import { testimonialsV2 } from "../../../data/testimonial";

const TestimonialSection: React.FC = () => {
  return (
    <div className="bg-gray-100 p-4 md:p-8 rounded-lg shadow-md my-20 py-10  mx-6 lg:mx-24">
      <p className="text-center text-gray-600 mb-4">Testimonials</p>
      <h1 className="text-3xl font-semibold text-center mb-2">
        Apa Kata Alumni Tentang Garis Prtama
      </h1>
      <TestimonialSlider testimonialData={testimonialsV2} />
    </div>
  );
};

export default TestimonialSection;
