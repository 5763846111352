import Image from "next/image";
import React from "react";

interface CategoryItemProps {
  image: string;
  name: string;
  position: string;
}

const CategoryItem: React.FC<CategoryItemProps> = ({
  image,
  name,
  position,
}) => {
  return (
    <div className="relative w-full h-64 bg-black bg-opacity-50 rounded-lg overflow-hidden">
      <Image
        src={image}
        alt={name}
        fill
        style={{ objectFit: "cover" }}
        className="rounded-lg"
        priority
      />
      <div className="absolute inset-0 flex items-center justify-center">
        <h3 className="text-white text-3xl w-1/2 text-center font-medium">
          {name}
        </h3>
      </div>
    </div>
  );
};

export default CategoryItem;
