//user
export const User_API = "/api/users";
export const USER_PROFILE_API = "/api/users/profile";
export const USER_NAME_API = "/api/users/profile?onlyName=true";

//course
export const COURSES_PUBLIC_API = "/api/courses/public";
export const COURSE_API = "/api/courses";

//enroll
export const COURSE_ENROLL_API = "/api/courses/enrolled";

//module
export const COURSE_MODULE_API = "/api/courses/module";

//cart
export const CART_API = "/api/cart";

//transaction
export const TRANSACTION_API = "/api/transaction";
