export const categoriesV2 = [
  {
    name: "Kelas Desain",
    image: "/images/category/1.webp",
    position: "object-center",
  },
  {
    name: "AI-Art",
    image: "/images/category/2.webp",
    position: "object-center ",
  },
  {
    name: "Animasi",
    image: "/images/category/3.webp",
    position: "object-center",
  },
  {
    name: "Microstok",
    image: "/images/category/4.webp",
    position: "object-bottom",
  },
];
