import Cookies from "js-cookie";

const COOKIE_NAME = "cartId";
const COOKIE_COURSE_NAME = "courseId";

//Cart
export const setCartIdCookie = (cartId: string) => {
  Cookies.set(COOKIE_NAME, cartId);
};

export const getCartIdCookie = () => {
  return Cookies.get(COOKIE_NAME);
};

export const removeCartIdCookie = () => {
  Cookies.remove(COOKIE_NAME);
};

//Course
export const setCourseIdCookie = (courseId: string) => {
  Cookies.set(COOKIE_COURSE_NAME, courseId);
};

export const getCourseIdCookie = () => {
  return Cookies.get(COOKIE_COURSE_NAME);
};

export const removeCourseIdCookie = () => {
  Cookies.remove(COOKIE_COURSE_NAME);
};
